const availableLanguages = ['NL','EN','DE'];

export function getInitialLanguage()
{
    const browserLanguage = getBrowserLanguage();

    if (browserLanguage
        && availableLanguages.includes(browserLanguage))
    {
        return browserLanguage;
    }
    else
    {
        return 'EN';
    }
}

function getBrowserLanguage()
{
    const browserLanguageTag = navigator.language || (navigator as any).userLanguage;

    if (browserLanguageTag)
    {
        const split = browserLanguageTag.split('-');

        if (split.length > 0)
        {
            return split[0].toUpperCase();
        }
    }

    return undefined;
}
