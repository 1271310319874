import React, {useMemo} from 'react';
import '../App.css';
import {Button, ButtonGroup, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper} from "@material-ui/core";

const Flags = require('country-flag-icons/react/3x2');

export interface LanguageFlagProps
{
    language: string;
}

export const LanguageFlag =
    ({
         language
    }: LanguageFlagProps) =>
    {
        const countryCode =
            useMemo(
                () =>
                {
                    if (language === 'EN')
                    {
                        return 'GB';
                    }
                    else
                    {
                        return language;
                    }
                },
                [
                    language
                ]);
        const flagProps =
            useMemo(
                () => ({
                    style: {
                        width: 25
                    }
                }),
                []);

        return React.createElement(
            Flags[countryCode],
            flagProps);
    };
