import React from 'react';
import Background from './Background/Background.jpg';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {CssBaseline, makeStyles} from "@material-ui/core";
import {blue, orange} from "@material-ui/core/colors";
import {Form} from "./Form";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Selector} from "./Selector";

const theme = createMuiTheme({
    palette: {
        primary: orange,
        secondary: blue,
        background: {
            default: '#2ca242'
        }
    }
});

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    background: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        background: '#2ca242'
    },
    backgroundImage: {
        width: '100%'
    },
    content: {
        marginTop: 15,
        width: 'min(90vw, 800px)'
    },
    card: {
        backgroundColor: 'rgba(255, 255, 255, 0.93)'
    },
    cardContent: {
        display: 'grid',
        rowGap: 30,
        position: 'relative'
    },
    languageSelector: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    flagButton: {
        border: 0,
        '&:hover': {
            border: 0
        }
    },
    flagButtonEnabled: {

    },
    flag: {
        width: 25
    },
    logo: {
        display: 'flex',
        justifyContent: 'center'
    },
    logoImage: {
        maxWidth: '70%'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
    },
    openMenuButton: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        fontWeight: 600
    },
    poweredBy: {
        width: '100%',
        textAlign: 'end',
        marginTop: 8,
        color: 'white'
    }
});

export const App =
    () =>
    {
        const classes = useStyles();

        return <BrowserRouter>
            <React.Fragment>
                <CssBaseline />
                <ThemeProvider
                    theme={theme}
                >
                    <div
                        className={classes.root}
                    >
                        <div
                            className={classes.background}
                        >
                            <img
                                src={Background}
                                className={classes.backgroundImage}
                                alt="Background"
                            />
                        </div>
                        <div
                            className={classes.content}
                        >
                            <Switch>
                                <Route
                                    path="/snackbar"
                                >
                                    <Form
                                        title="Snackbar"
                                        placeCode="oke0n"
                                    />
                                </Route>
                                <Route
                                    path="/pizza"
                                >
                                    <Form
                                        title="Pizza"
                                        placeCode="P7g2P"
                                    />
                                </Route>
                                <Route
                                    path="/"
                                >
                                    <Selector />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </ThemeProvider>
              </React.Fragment>
        </BrowserRouter>;
    };
