import React, {useCallback, useMemo, useState} from 'react';
import Logo from './Logo/Duinrell.svg';
import Background from './Background/Background.jpg';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {
    Button,
    Card,
    CardContent,
    CssBaseline,
    FormControl,
    FormControlLabel,
    FormLabel,
    makeStyles,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import {blue, orange} from "@material-ui/core/colors";
import {LanguageSelector} from "./LanguageSelector/LanguageSelector";
import { LanguageContext } from './Language/LanguageContext';
import {Translation} from "./Translation/Translation";
import {getInitialLanguage} from "./Translation/Api/getInitialLanguage";
import {Link} from "react-router-dom";

const theme = createMuiTheme({
    palette: {
        primary: orange,
        secondary: blue
    }
});

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    background: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        background: '#2ca242'
    },
    backgroundImage: {
        width: '100%'
    },
    content: {
        marginTop: 15,
        width: 'min(90vw, 800px)'
    },
    card: {
        backgroundColor: 'rgba(255, 255, 255, 0.93)'
    },
    cardContent: {
        display: 'grid',
        rowGap: 30,
        position: 'relative'
    },
    languageSelector: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    flagButton: {
        border: 0,
        '&:hover': {
            border: 0
        }
    },
    flagButtonEnabled: {

    },
    flag: {
        width: 25
    },
    logo: {
        display: 'flex',
        justifyContent: 'center'
    },
    logoImage: {
        maxWidth: '70%'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
    },
    openMenuButton: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        fontWeight: 600
    },
    poweredBy: {
        width: '100%',
        textAlign: 'end',
        marginTop: 8,
        color: 'white'
    }
});

export const Selector =
    () =>
    {
        const classes = useStyles();

        const openSnackbar =
            useCallback(
                () =>
                {
                    window.location.href = './snackbar';
                },
                []);
        const openPizza =
            useCallback(
                () =>
                {
                    window.location.href = './pizza';
                },
                []);

        return <Card
            className={classes.card}
        >
            <CardContent>
                <div
                    className={classes.cardContent}
                >
                    <div
                        className={classes.logo}
                    >
                        <img
                            className={classes.logoImage}
                            src={Logo}
                            alt="Logo"
                        />
                    </div>
                    <div
                        className={classes.form}
                    >
                        <Button
                            className={classes.openMenuButton}
                            onClick={openSnackbar}
                        >
                            Snackbar
                        </Button>
                        <Button
                            className={classes.openMenuButton}
                            onClick={openPizza}
                        >
                            Pizza
                        </Button>
                    </div>
                </div>
            </CardContent>
        </Card>;
    };
