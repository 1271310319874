import React from 'react';
import '../App.css';
import {Button, ButtonGroup, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper} from "@material-ui/core";
import {LanguageFlag} from "./LanguageFlag";

const options = ['NL', 'EN', 'DE'];

export interface LanguageSelectorProps
{
    value: string;
    onChange: (value: string) => void;
}

export const LanguageSelector =
    ({
         value,
         onChange
    }: LanguageSelectorProps) =>
    {
        const [open, setOpen] = React.useState(false);
        const anchorRef = React.useRef<HTMLDivElement>(null);

        const handleToggle = () => {
            setOpen((prevOpen) => !prevOpen);
        };

        const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
            if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
                return;
            }

            setOpen(false);
        };

        return (
            <Grid container direction="column" alignItems="center">
                <Grid item xs={12}>
                    <ButtonGroup variant="text" color="primary" ref={anchorRef} aria-label="split button">
                        <Button onClick={handleToggle}>
                            <LanguageFlag
                                language={value}
                            />
                        </Button>
                    </ButtonGroup>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu">
                                            {options.map((option, index) => (
                                                <MenuItem
                                                    key={option}
                                                    selected={option === value}
                                                    onClick={(event) => { onChange(option); setOpen(false); }}
                                                >
                                                    <LanguageFlag
                                                        language={option}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
            </Grid>
        );
    };
