import React, {useContext} from 'react';
import '../App.css';
import {LanguageContext} from "../Language/LanguageContext";
import {translate} from "./Api/translate";

export interface TranslationProps
{
    code: string;
}

export const Translation =
    ({
         code
    }: TranslationProps) =>
    {
        const language = useContext(LanguageContext);

        return <>{translate(code, language)}</>;
    };
