import React, {useCallback, useMemo, useState} from 'react';
import Logo from './Logo/Duinrell.svg';
import {
    Button,
    Card,
    CardContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    makeStyles,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import {LanguageSelector} from "./LanguageSelector/LanguageSelector";
import {LanguageContext} from './Language/LanguageContext';
import {Translation} from "./Translation/Translation";
import {getInitialLanguage} from "./Translation/Api/getInitialLanguage";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    background: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        background: '#2ca242'
    },
    backgroundImage: {
        width: '100%'
    },
    content: {
        marginTop: 15,
        width: 'min(90vw, 800px)'
    },
    card: {
        backgroundColor: 'rgba(255, 255, 255, 0.93)'
    },
    cardContent: {
        display: 'grid',
        rowGap: 30,
        position: 'relative'
    },
    languageSelector: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    flagButton: {
        border: 0,
        '&:hover': {
            border: 0
        }
    },
    flagButtonEnabled: {

    },
    flag: {
        width: 25
    },
    logo: {
        display: 'flex',
        justifyContent: 'center'
    },
    logoImage: {
        maxWidth: '70%'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
    },
    openMenuButton: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        fontWeight: 600
    },
    poweredBy: {
        width: '100%',
        textAlign: 'end',
        marginTop: 8,
        color: 'white'
    }
});

export interface FormProps
{
    title: string;
    placeCode: string
}

export const Form =
    ({
        title,
        placeCode
     }: FormProps) =>
    {
        const classes = useStyles();
        const [ language, setLanguage ] = useState(() => getInitialLanguage());
        const [ agency, setAgency ] = useState('Duinrell');
        const [ accommodationType, _setAccommodationType ] = useState('Bungalow');
        const [ isCampingFieldValid, setCampingFieldValidity ] = useState(true);
        const [ campingField, _setCampingField ] = useState<string>('');
        const setCampingField =
            useCallback(
                (campingField) =>
                {
                    _setCampingField(campingField);
                    setCampingFieldValidity(campingField.length > 0);
                },
                [
                    _setCampingField,
                    setCampingFieldValidity
                ]);
        const [ isPlaceNumberValid, setPlaceNumberValidity ] = useState(true);
        const [ placeNumber, _setPlaceNumber ] = useState<string>('');
        const setPlaceNumber =
            useCallback(
                (placeNumber) =>
                {
                    _setPlaceNumber(placeNumber);
                    setPlaceNumberValidity(placeNumber.length > 0);
                },
                [
                    _setPlaceNumber,
                    setPlaceNumberValidity
                ]);
        const setAccommodationType =
            useCallback(
                (type: string) =>
                {
                    if (type !== 'Camping')
                    {
                        setCampingField('');
                    }

                    _setAccommodationType(type);
                },
                [
                    setCampingField,
                    _setAccommodationType
                ]);
        const campingFields =
            useMemo(
                () => [
                    'Eiland',
                    'Middenveld',
                    'Lindenveld',
                    'Romantisch kamp',
                    'Beukenveld',
                    'Duinvallei',
                    'Dennenveld',
                    'Paardenwei',
                    'Bosrand',
                    'Koetsveld',
                    'Hertenkamp',
                    'Oever'
                ],
                []);

        const openMenu =
            useCallback(
                () =>
                {
                    if (accommodationType === 'Camping' && campingField === '')
                    {
                        setCampingFieldValidity(false);

                        return;
                    }
                    else
                    {
                        setCampingFieldValidity(true);
                    }

                    if (placeNumber === '')
                    {
                        setPlaceNumberValidity(false);

                        return;
                    }
                    else
                    {
                        setPlaceNumberValidity(true);
                    }

                    const locationDescription = [
                        agency,
                        accommodationType,
                        campingField
                    ].filter(
                        element =>
                            element !== '')
                        .join(' ');
                    const orderDestinationAddress = {
                        street: locationDescription,
                        number: placeNumber,
                        postalCode: '2242JP',
                        city: 'Wassenaar',
                        country: 'NL'
                    };
                    const orderComment = `${locationDescription} ${placeNumber}`;

                    window.location.href = `https://butlaroo.app/place/${placeCode}?orderDestinationAddress=${encodeURIComponent(JSON.stringify(orderDestinationAddress))}&orderComment=${encodeURIComponent(orderComment)}`;
                },
                [
                    agency,
                    accommodationType,
                    campingField,
                    placeNumber,
                    setCampingFieldValidity,
                    setPlaceNumberValidity,
                    placeCode
                ]);

        return <LanguageContext.Provider
            value={language}
        >
            <Card
                className={classes.card}
            >
                <CardContent>
                    <div
                        className={classes.cardContent}
                    >
                        <div
                            className={classes.logo}
                        >
                            <img
                                className={classes.logoImage}
                                src={Logo}
                                alt="Logo"
                            />
                        </div>
                        <div
                            className={classes.languageSelector}
                        >
                            <LanguageSelector
                                value={language}
                                onChange={setLanguage}
                            />
                        </div>
                        <div>
                            <Typography
                                variant="h5"
                            >
                                {title}
                            </Typography>
                            <Typography>
                                <Translation
                                    code="Subtitle"
                                />
                            </Typography>
                        </div>
                        <div
                            className={classes.form}
                        >
                            <FormControl
                                component="fieldset"
                            >
                                <FormLabel
                                    component="legend"
                                    required
                                >
                                    <Translation
                                        code="AgencyLabel"
                                    />
                                </FormLabel>
                                <RadioGroup
                                    aria-label="agency"
                                    name="agency"
                                    value={agency}
                                    onChange={(e, value) => setAgency(value)}
                                >
                                    <FormControlLabel
                                        value="Duinrell"
                                        control={<Radio />}
                                        label={
                                            <Translation
                                                code="DirectAtDuinrell"
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        value="Eurocamp"
                                        control={<Radio />}
                                        label="Eurocamp"
                                    />
                                    <FormControlLabel
                                        value="Canvas"
                                        control={<Radio />}
                                        label="Canvas"
                                    />
                                    <FormControlLabel
                                        value="Al Fresco"
                                        control={<Radio />}
                                        label="Al Fresco"
                                    />
                                </RadioGroup>
                            </FormControl>
                            <FormControl
                                component="fieldset"
                            >
                                <FormLabel
                                    component="legend"
                                    required
                                >
                                    <Translation
                                        code="AccommodationTypeLabel"
                                    />
                                </FormLabel>
                                <RadioGroup
                                    aria-label="accomodationType"
                                    name="accomodationType"
                                    value={accommodationType}
                                    onChange={(e, value) => setAccommodationType(value)}
                                >
                                    <FormControlLabel
                                        value="Bungalow"
                                        control={<Radio />}
                                        label={
                                            <Translation
                                                code="AccommodationType-Bungalow"
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        value="Lodgetent"
                                        control={<Radio />}
                                        label={
                                            <Translation
                                                code="AccommodationType-Lodgetent"
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        value="Camping"
                                        control={<Radio />}
                                        label={
                                            <Translation
                                                code="AccommodationType-Camping"
                                            />
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                            {
                                accommodationType === 'Camping' &&
                                    <FormControl
                                        component="fieldset"
                                        error={!isCampingFieldValid}
                                    >
                                        <FormLabel
                                            component="legend"
                                            required
                                        >
                                            <Translation
                                                code="CampingFieldLabel"
                                            />
                                        </FormLabel>
                                        <Select
                                            native
                                            value={campingField}
                                            onChange={e => setCampingField(e.target.value as string)}
                                        >
                                            <option aria-label="None" value="" />
                                            {
                                                campingFields.map(
                                                    campingField =>
                                                        <option
                                                            key={campingField}
                                                            value={campingField}
                                                        >
                                                            {campingField}
                                                        </option>)
                                            }
                                        </Select>
                                    </FormControl>
                            }
                            <FormControl
                                component="fieldset"
                                error={!isPlaceNumberValid}
                            >
                                <FormLabel
                                    component="legend"
                                    required
                                >
                                    <Translation
                                        code="PlaceNumberLabel"
                                    />
                                </FormLabel>
                                <TextField
                                    required
                                    value={placeNumber}
                                    onChange={e => setPlaceNumber(e.target.value)}
                                    type="number"
                                />
                            </FormControl>
                            <Button
                                className={classes.openMenuButton}
                                onClick={openMenu}
                            >
                                <Translation
                                    code="GoToMenuButtonLabel"
                                />
                            </Button>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <div
                className={classes.poweredBy}
            >
                <Translation
                    code="PoweredByLabel"
                />
            </div>
        </LanguageContext.Provider>;
    };
