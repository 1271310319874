import NL from '../Translations.NL.json';
import EN from '../Translations.EN.json';
import DE from '../Translations.DE.json';

const TranslationsByLanguage = {
    NL: NL,
    EN: EN,
    DE: DE
};

export function translate(code: string,
                          language: string)
{
    return (TranslationsByLanguage as any)[language][code];
}
